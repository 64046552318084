.relative {
  position: relative;
}

.icon {
  /* position: absolute;
    top: 50%;
    right: 20px;
    font-size: 20px;
    transform: translateY(-50%);
    cursor: pointer; */
  float: right;
  margin-top: -45px;
  margin-right: 12px;
  position: relative;
  z-index: 2;
}

.terms {
  display: flex;
}

.error {
  color: #d93025;
  position: relative;
  font-size: 0.9rem;
  bottom: 0.3rem;
}
.f-error {
  color: #d93025;
  font-size: 0.9rem;
}

.svgicon {
  color: #D6D4CD;
  /* color: #b1b1b1; */
}

.iconsvg {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.upload {
  text-align: center;
}

/* Login Page CSS */
.login-wrapper:before {
  content: url("../src/assets/images/Vector\ 3.png");
  overflow-y: hidden;
  height: 100%;
  z-index: 5;
  position: absolute;
}

.login-wrapper:after {
  content: url("../src/assets/images/Vector\ 4.png");
  overflow-x: hidden;
  height: 100%;
  z-index: 5;
  position: absolute;
  top: 0;
  left: 55px;
}

.box-2 {
  width: 100%;
  height: 100%;
  background: rgb(46, 48, 146, 1);
  background: linear-gradient(90deg,
      rgba(46, 48, 146, 90%) 0%,
      rgba(46, 127, 121, 90%) 64%,
      rgba(57, 169, 87, 90%) 100%);
  z-index: 1;
  clip-path: polygon(100% 0, 0 100%, 0 0);
}

.box-2-heading {
  position: absolute;
  top: 25%;
  left: 5%;
}

.left {
  position: absolute;
  top: 40%;
  left: 10%;
}

.box-2-p {
  font-size: 32px;
  font-weight: 600;
  font-style: italic;
}

.kitchen-heading {
  font-size: 68px;
  line-height: 1;
}

.fs-200 {
  font-size: 200px;
  line-height: 0px;
}

.fs-50 {
  font-size: 40px;
  margin-bottom: 0;
  font-weight: 700;
  line-height: 50px;
}

.kitchen-r {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1440px) {
  .login-wrapper:after {
    content: ""
  }

  .kitchen-heading {
    font-size: 52px;
  }

  .fs-200 {
    font-size: 150px;
  }

  .fs-50 {
    font-size: 30px;
    line-height: 35px;
  }

  .box-2-p {
    font-size: 22px;
  }

  .box-span::before {
    width: 105px;
    left: 65px;
  }

  .box-span::after {
    width: 105px;
    right: 65px;
  }

  .appliances-container img {
    width: 50%;
  }
}

@media screen and (max-width: 1024px) {
  .box-2-heading {
    display: none;
  }

  .box-span::before {
    width: 100px;
    left: 150px;
  }

  .box-span::after {
    width: 100px;
    right: 150px;
  }
}

@media screen and (max-width: 768px) {
  .box-span::before {
    width: 100px;
    left: 70px;
  }

  .box-span::after {
    width: 100px;
    right: 70px;
  }

  .banner-small {
    width: 70%;
  }
}

@media screen and (max-width: 425px) {
  .login-wrapper:before {
    content: "";
  }

  .box-span::before {
    width: 100px;
    left: 15px;
  }

  .box-span::after {
    width: 100px;
    right: 15px;
  }

  .button-small {
    width: 60%;
  }

  .small-100 {
    width: 100%;
  }

  .menu {
    display: none;
  }

  .appliances-container img {
    width: 100%;
  }

  .mobile-flex-wrap {
    flex-wrap: wrap;
  }

  .mobile-full {
    width: 100%;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1025px),
(max-width: 425px) {
  .terms input {
    margin-bottom: 24px;
  }
}

@media screen and (min-width: 768px) {
  .hamburger {
    display: none !important;
  }
}

.hamburger {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index: 10;
  margin-right: 10px;
}

.burger {
  width: 2rem;
  height: 0.25rem;
  border-radius: 10px;
  background-color: black;
  transform-origin: 1px;
  transition: all 0.3s linear;
}

.mobile-menu .mob-ul {
  position: absolute;
  z-index: 99;
  width: 100%;
  background: white;
}

.mobile-menu .mob-ul li {
  border-bottom: 1px solid #f3f3f3;
}

.mobile-show {
  top: 71px;
  transition: all 0.3s linear;
}

.mobile-hide {
  top: -170px;
  transition: all 0.3s linear;
}

.header {
  background-color: white;
  z-index: 999;
  border-bottom: 1px solid #ccc;
}

/* Appliances Page  */
.custom-padding {
  padding: 12px;
}

.counter-bar {
  border: 1px solid white;
}

.counter-bar {
  background-color: #f1f1f1;
  height: 20px;
  position: relative;
}

.counter-percent {
  height: 18px;
}

.counter-percent {
  display: block;
}

.score1 {
  /* background: rgb(46,48,146); */
  background: linear-gradient(90deg, rgba(46, 48, 146, 1) 0%, rgba(46, 127, 119, 1) 35%, rgba(57, 169, 87, 1) 100%);
  width: 90%;
}

.score2 {
  /* background: rgb(46,48,146); */
  background: linear-gradient(90deg, rgba(46, 48, 146, 1) 0%, rgba(46, 127, 119, 1) 35%, rgba(57, 169, 87, 1) 100%);
  width: 50%;
}

.score3 {
  /* background: rgb(46,48,146); */
  background: linear-gradient(90deg, rgba(46, 48, 146, 1) 0%, rgba(46, 127, 119, 1) 35%, rgba(57, 169, 87, 1) 100%);
  width: 85%;
}

.score1::after {
  content: "90%";
  font-size: 14px;
  color: white;
  position: absolute;
  left: 310px;
}

.score2::after {
  content: "50%";
  font-size: 14px;
  color: white;
  position: absolute;
  left: 165px;
}

.score3::after {
  content: "85%";
  font-size: 14px;
  color: white;
  position: absolute;
  left: 300px;
}


/* Drop Down CSS  */
.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-bottom: 2px;
  margin-left: 10px;
}

.p-arrow {
  border: solid rgb(255, 255, 255);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  /* margin-bottom: 2px; */
  /* margin-left: 80px; */
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}


.log-options {
  background-color: #F6F6F6;
  /* padding: 10px; */
  width: 160px;
  text-align: center;
  top: 73px;
  right: inherit
}

.log-options li {
  border-bottom: 1px solid #9f9f9f;
  padding: 7px 0;
}

.log-options li:last-child {
  border: none;
}

.project-options{
  background-color: #F6F6F6;
  /* padding: 10px; */
  width: 265px;
  text-align: center;
  right: inherit
}
.project-options li{
  border-bottom: 1px solid #9f9f9f;
  padding: 7px 0;
}
.project-options li:last-child{
  border: none;
}

.lic-btn1 {
  background-color: red;
}

.lic-table td {
  padding: 9px 30px;
}

.table-wrapper {
  overflow-y: auto;
  height: 570px;
}

.main-overlay {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
  position: fixed;
  top: 0;
  z-index: 999;
}

.cus-padding {
  padding: 0px 3px;
}

/* Cabinet CSS */
.left-sidebar {
  height: 100vh;
  float: left;
  width: 330px;
  /* background-color: red; */
}

.left-sidebar1 {
  height: 100vh;
  width: 110px;
  /* background-color: green; */
}

.right-wrapper {
  height: 100vh;
  float: right;
  /* width: 445px; */
}

.right-sidebar1 {
  height: 100vh;
  width: 350px;
}

.button-grp {
  overflow-x: scroll;
}

.button-grp::-webkit-scrollbar {
  display: none;
}


/* .new--bg{
  background-image: url(../src//assets/images/cabinet-test.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
} */

/* .right-toggle{
  margin-right: -63px;
  width: 184px;
} */
.left-inactive {
  margin-left: -317px;
  transition: .3s ease-in-out;
}

.left-active {
  margin-left: 0;
  transition: .3s ease-in-out;
}

.right-active {
  margin-right: 0;
  transition: .3s ease-in-out;
}

.right-inactive {
  margin-right: -480px;
  transition: .3s ease-in-out;
}

.right-icon {
  width: 50px;
}

.paint-box {
  width: 90px;
  height: 90px;
}

.cabinet-box img {
  width: 87px;
  height: 144px;
}

.active-btn {
  color: white;
  background: #585da6;
  box-shadow: inset 0px 0px 0px 2px #fff;
  font-weight: 600;
}

.hr-scope {
  border: none;
  height: 1px;
  color: #9F9F9F;
  /* old IE */
  background-color: #9F9F9F;
  /* Modern Browsers */
}

.active-filter {
  background-color: rgba(255, 255, 255, 0.9);
  color: #585da6;
  font-weight: 500;
}


/* CSS FOR CUSTOM SCROLL BAR FOR CHAT */
  .custom-scroll::-webkit-scrollbar {
    width: 4px;
  }
  /* Track */
  .custom-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  /* Handle */
  .custom-scroll::-webkit-scrollbar-thumb {
    background: #2E3092;
    border-radius: 10px;
  }
  /* Handle on hover */
  .custom-scroll::-webkit-scrollbar-thumb:hover {
    background: #2E7F77
  }


  /* Rate Input For Admin Billing  */
  .rate-input{
    width: 110px;
    /* height: 40px; */
    padding: 8px 10px;
    margin-top: -10px;
  }
  .amt-input{
    width: 200px;
    /* height: 40px; */
    padding: 8px 10px;
    margin-top: -10px;
  }

  .custom-bill-banner{
    background-image: url(../public/Assets/Images/banner-bg.jpg);
    background-attachment: fixed;
  }


  /* Design Change Plus Icon Style  */
  .wcabinet-changer{
    top: 310px;
    right: 980px
  }
  .bcabinet-changer{
    top: 520px;
    right: 350px
  }
  .floor-changer{
    top: 690px;
    right: 300px;
  }
  .paint-changer{
    top: 180px;
    right: 410px
  }
  /*END Design Change Plus Icon Style  */


  .mid-container-pad{
    padding-top: 55px;
  }

  .size-animate{
    animation-name: bigsize;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    transition: ease-in-out;
  }
  @keyframes bigsize {
    0% {transform: scale(1)}
    50% {transform: scale(1.2)}
  }

  .manual-msr{
    background-image: none;
    background-color: #fff;
  }

  .manual-msr .border-black {
    border-width: 1px;
    border-color: #ccc;
    border-radius: 7px;
    margin-right: 10px;
}

.manual-msr button.border-black{
  background-color: #2E3092;
  color: #fff;
}

.manual-msr h3.text-black{
  color: #2E3092;
  font-weight: 600;
}

.v1-floor-changer{
  top: 690px;
    right: 350px;
}
.v1-countertop-changer{
  top: 565px;
    right: 175px;
}
.v1-paint-changer{
  top: 335px;
  right: 1190px;
}
.v1-backsplash-changer{
  top: 475px;
  right: 300px;
}
.v1-wcabinet-changer{
  top: 315px;
  right: 280px;
}
.v1-bcabinet-changer{
  top: 575px;
  right: 370px;
}
.v1-icabinet-changer{
  top: 610px;
  right: 500px;
}

.v2-backsplash-changer{
  top: 415px;
  right: 300px;
}
.v2-floor-changer{
  top: 740px;
  right: 350px;
}
.v2-paint-changer{
  top: 280px;
  right: 140px;
}
.v2-countertop-changer{
  top: 485px;
  right: 480px;
}
.v2-icabinet-changer{
  top: 550px;
  right: 300px;
}
.v2-bcabinet-changer{
  top: 490px;
    right: 930px;
}
.v2-wcabinet-changer{
  top: 315px;
  right: 280px;
}
.selector-bg{
  background-color: rgba(0, 0, 0, 0.315);
}
.selector-bg:hover{
  background-color: rgba(0, 0, 0, 0.600);
}

#role option{
  color: black;
  border: 1px solid black; 
  padding: 10px 5px;
  font-weight: 600;
}