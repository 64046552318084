@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --primary-color: #2E3092;
  --light-color: #9F9F9F;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@layer components {
  .chat-header{
    @apply flex items-center justify-between h-[73] bg-black px-4 py-3 rounded-t-lg bg-gradient-to-r from-[rgba(46,127,119,.8)] to-[rgba(46,48,146,.8)]
  }
  .user-chat{
    @apply my-2 border px-4 py-3 rounded-br-none rounded-xl bg-gradient-to-r from-[rgba(98,100,195,.3)] to-[rgba(161,243,172,.3)]
  }
  .card {
    @apply p-12 rounded-md shadow-md xl:w-[30%] md:w-8/12 w-full mx-2 md:mx-0 bg-white
  }

  .design-selector{
    @apply absolute text-base font-semibold top-0 border rounded-full p-2 border-white border-2 text-white cursor-pointer
  }
  select {
    @apply border border-gray-300 w-full block rounded h-11 mb-3 px-3
  }

  .p-card {
    @apply p-12 shadow-md md:w-4/12 w-full bg-white mb-3 md:mb-0
  }

  h1 {
    @apply font-bold text-4xl pb-3 text-[#222222]
  }

  input[type=text] {
    @apply border border-gray-300 w-full block rounded h-11 mb-3 px-3 
  }

  input[type=date] {
    @apply border border-gray-300 w-full block rounded h-11 mb-3 px-3 
  }

  input[type=email] {
    @apply border border-gray-300 w-full block rounded h-11 mb-3 px-3 
  }

  input[type=password] {
    @apply border border-gray-300 w-full block rounded h-11 mb-3 px-3 
  }

  .check {
    @apply inline w-4 h-3.5
  }

  .primary-btn {
    @apply bg-primary-color text-white w-full rounded h-11
  }

  .or {
    @apply before:absolute before:w-5/12 before:top-2.5 before:bg-light-color before:h-px
  }

  .for {
    @apply after:absolute after:w-5/12 after:top-2.5 after:bg-light-color after:h-px after:right-0
  }

  .banner-grad {
    @apply before:absolute before:top-0 before:right-0 before:bottom-0 before:bg-gradient-to-r from-gray-950/75 to-transparent before:w-full before:h-full before:z-0
  }
  .login-grad {
    @apply before:absolute before:top-0 before:right-0 before:bottom-0 before:w-full before:h-full before:z-0
  }
  
.box-1 {
  @apply p-12 rounded-none shadow-md xl:w-[35%] md:w-8/12 w-full mx-2 md:mx-0 bg-gradient-to-r from-[rgba(46,53,144,.8)] to-[rgba(51,97,144,.8)]
}
.box-cus {
  @apply p-12 rounded-none shadow-md xl:w-[73%] md:w-8/12 w-full mx-2 md:mx-0 bg-gradient-to-r from-[rgba(46,53,144,.8)] to-[rgba(51,97,144,.8)]
}
.box-lic {
  @apply p-12 rounded-none shadow-md xl:w-[20%] md:w-8/12 w-full mx-2 md:mx-0 bg-gradient-to-r from-[rgba(46,53,144,.8)] to-[rgba(51,97,144,.8)]
}
.grad-color{
  @apply bg-gradient-to-r from-[rgba(46,53,144,.8)] to-[rgba(51,97,144,.8)]
}

.box-h1{
  @apply text-4xl pb-1 text-white text-center
}

.box-p{
  @apply text-white  text-4xl pb-1 text-center
}

.info{
  @apply text-3xl font-medium pb-4 text-center
}

.box-h6{
  @apply text-white text-center pt-1 text-sm text-center
}

.box-btn{
  @apply text-white w-6/12 rounded h-11 border-white border-solid border-2
}

.image-p{
  @apply text-xl pb-1 text-white text-center
}
.image-desc{
  @apply text-xl pb-1 text-white
}

.box-image{
  @apply text-white text-center text-sm pb-4
}

.box-span{
  @apply text-white text-center pt-1 text-lg pb-2
}
.box-btn-img{
  @apply text-white pr-8 pl-8 rounded h-11 border-white border-solid border-2 
}

.arrow-img{
 margin-left: 10px;
    /* margin-right: 20px; */
    height: auto;
    width: 42px;
}
.ml-2 {margin-left: 0.5rem }
}
.lets-top{
  margin-bottom: 20px;
}

.login-wrapper {
  background-image: url(../public/Assets/Images/banner-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  /* position: relative; */
  /* z-index: 9; */
  background-position: 0%;
  height: 100vh;
  width: 100%;
}

.login-wrapper::before {
  z-index: -1;
}
.main-wrapper {
  background-image: url(../public/Assets/Images/banner-bg.jpg);
  position: relative;
  z-index: 9;
}

.main-wrapper::before {
  z-index: -1;
}


.box-span{
  position: relative;
}
.box-span::after{
  content: "";
  display: block;
  width: 90px;
  height: 1px;
  background-color: #ffffff;
  position: absolute;
  top: 44%;
  right: 145px;
}
.box-span::before{
  content: "";
  display: block;
  width: 90px;
  height: 1px;
  background-color: #ffffff;
  position: absolute;
  top: 44%;
    left: 145px 
  

}
.transprent{
  background: transparent;
  color: #ffffff;
  font-weight: 600;
}

/* input[type=text] {
  width: 50%;
} */
.user2{
  width: 100% !important;
}


/* .side-bar{
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    height: 80%;
} */



/* ::placeholder{
  color: #ffffff !important;
} */
/* @media(max-width:1024px){
  .LoginModal{
    width: 50%;
  }
}

@media(min-width:1024px){
  .LoginModal{
    width: 50%;
  }
}

@media(min-width:1399px){
  .LoginModal{
    width: 25%;
  }
} */
/* .main-wrapper:before {
  content: url(../public/Assets/Images/Vector\ 3.png);
  height: 100%;
  z-index: 5;
  position: absolute;
}
.main-wrapper:after {
  content: url(../public/Assets/Images/Vector\ 4.png);
  height: 100%;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 55px;
} */

.project-type{
  border: 1px solid white;
  padding: 9px;
  border-radius: 3px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
}